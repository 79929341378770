.contact-wrapper {
    height: auto;
    position: relative;
    background-color: #eee;
    padding-bottom: 2rem;
    margin: 0;
}

.dark-mode .contact-wrapper {
    background-color: #1d1d1d;
}

.contact-wrapper .title {
    margin: 0 4rem;
    padding-top: 2rem;
}

.contact-wrapper > svg {
    position: absolute;
    top: -20%;  
    z-index: -1;
}

.dark-mode .contact-container {
    background-color: #1d1d1d;
}

.contact {
    display: flex;
    justify-content: space-evenly;
    max-width: 1560px;
    margin: 0 4rem;
}

.contact-container .title {
    margin: 2rem 4rem;
}

.secondcontact {
    position: relative;
}

.leftContact {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 10px;
    flex: 1;
}

.contactinfo {
    display: flex;
    align-items: center;
    gap: 20px;
}

.contactinfo svg {
    font-size: 1.5rem;
}

.rightContact {
    height: 100%;
    flex: 1;
}

.textInfo h2 {
    padding-bottom: 10px;
}

.rightContact form {
    height: 100%;
    padding: 2em 4em;
    background-color: #16577d;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.dark-mode .rightContact form {
    background-color: #313131;
}

.flex-col {
    display: flex;
    flex-direction: column;
    border: none;
    justify-content: space-between;
    padding: 4rem;
    border-radius: 20px;
}

.inputBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1em 0;
}

.inputBox label {
    color: white;
}

.inputBox span {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1em;
}

.inputBox>span,
.inputBox>svg {
    color: white;
}

textarea {
    resize: none;
    font-family: 'Inter', sans-serif;
}

.inputBox input {
    border: 2px solid #000000;
}

.inputBox input:focus {
    outline: none;
}

.dark-mode .inputBox input {
    color: white;
}

input[type="text"] {
    margin-top: 10px;
    font-size: 18px;
    padding: 5px 5px;
    border: none;
    border-bottom: #dce9ed 2px solid;
    background: none;
    color: white;
}

input[type="email"] {
    margin-top: 10px;
    font-size: 18px;
    padding: 5px 5px;
    border: none;
    border-bottom: #dce9ed 2px solid;
    background: none;
    color: white;
}

.inputBox textarea {
    border-radius: 5px;
    border: 2px solid #000000;
    padding-top: 5px;
    padding-bottom: 6rem;
    padding-left: 5px;
    margin-top: 12px;
    font-size: 16px;
}

.inputBox.textarea {
    grid-column: 1 / -1;
}

.inputBox.submit {
    grid-column: 1 / -1;
    justify-content: center;
}

.inputBox textarea:focus {
    outline: none;
}

.dark-mode .inputBox textarea {
    border: 2px solid #ffffff;
}

.dark-mode .inputBox textarea {
    background-color: #313131;
    color: white;
}

.inputBox textarea {
    height: 100%;
}

.inputBox span > svg {
    font-size: 1.25em;
}

input[type=submit] {
    background-color: #dce9ed;
    color: black;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid #000000;
    font-weight: 600;
}

.dark-mode input[type=submit] {
    background-color: #313131;
    color: #dce9ed;
    border: 2px solid #ffffff;
    font-family: 'Inter', sans-serif;
}

input[type=submit]:hover {
    background-color: black;
    color: #dce9ed;
    transition: cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.3s;
}

@media screen and (max-width: 1064px) {

    .contact {
        margin: 0 2rem;
    }

    .rightContact form {
        grid-template-columns: 1fr;
        padding: 2em;
    }
}

@media screen and (max-width: 450px){

    .contactinfo {
        gap: 20px;
    }

    .leftContact {
        gap: 20px;
        width: 100%;
        padding-left: 2rem;
    }

    .contact-wrapper {
        height: auto;
        padding-top: 1vh;
    }

    .contact-wrapper .title {
        margin: 1rem 2rem;
    }

    .contact-container {
        height: 100%;
    }

    .contact {
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
        margin: 0;
    }

    .rightContact {
        padding: 2em 0;
        margin: 0 2rem;
    }

    .rightContact form {
        height: 100%;
        padding: 2em 2em;
    }
    .contact-wrapper > svg {
        position: absolute;
        top: 0;
        z-index: -1;
    }
    .inputBox svg, .inputBox span {
        font-size: 1em;
    }
    input[type=submit] {
        padding: 10px 20px;
        font-size: 1em;
    }
    .textInfo h2, .textInfo p {
        font-size: 1em;
    }
}