
.experienceswrapper {
    position: relative;
    min-height: 100dvh;
    padding-bottom: 100px;
}

.experienceswrapper > svg {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    border: none;
    margin: 0;
}

.experienceswrapper h1 {
    margin: 2rem 4rem;
}

.experiencescontainer {
    margin: 0 4rem;
}

.experience {
    margin: 40px 0;
}

.postInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding-top: 1em;
    gap: 10px;
}

.postInfo p {
    font-size: 1.25rem;
}

.experience h2 {
    position: relative;
    z-index: 9999;
}

.post-title {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.post-title > * {
    line-height: 1.5;
}

.postInfo::before {
    content: "";
    position: absolute;
    top: 5px;
    width: 80%;
    height: 4px;
    background-color: #000000;
}

.dark-mode .postInfo::before {
    background-color: #ffffff;
}

.years {
    font-weight: bold;
    color: gray;
}

body.dark-mode .years {
    color: rgb(198, 198, 198);
}

.location {
    font-weight: bold;
}

@media screen and (max-width: 1064px) {

    .experienceswrapper h1 {
        margin: 2rem 2rem;
    }

    .experiencescontainer {
        grid-template-columns: repeat(1, 1fr);
        gap: 10vh;
        margin: 0 2rem;
    }
}

@media screen and (max-width: 450px) {

    .experienceswrapper h1 {
        margin: 2rem 2rem;
    }
}