.skills-wrapper h1 {
    padding-bottom: 3rem;
    margin: 0 4rem;
    z-index: 1;
    position: relative;
}

.skills-wrapper {
    padding-top: 10px;
    background-color: #eee;
    min-height: 100dvh;
}

.dark-mode .skills-wrapper {
    background-color: #1d1d1d;;
}

.dark-mode .skills-wrapper h1 {
    position: relative;
    z-index: 999;
}

.skills-wrapper .svg-wrapper > svg {
    position: absolute;
    top: 11vh;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.dark-mode .svg-wrapper > svg {
    z-index: 1;
}

.dark-mode .svg-wrapper > svg {
    top: 22vh;
}

.dark-mode .svg-skills {
    z-index: 999;
}

.skills-experience {
    display: flex;
    height: auto;
}

.dark-mode .skills-experience {
    background-color: #1d1d1d;;
}

.skills-experience .title {
    margin: 2rem 4rem;
}

.skills {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto;
    justify-items: center;
    align-items: start; 
    gap: 20px;
    margin: 2rem ;
    flex: 1;
}

.dark-mode .skill .image .next-js {
    padding: 4px;
    background-color: white;
}

@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

.skill {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    background-color: white;
    min-height: 200px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 10px 10px #00000005;
    animation: fadeInUp 0.5s ease-in-out;
}

.skill p {
    text-align: center;
}

.dark-mode .skill {
    background-color: black;
    color: white;
    box-shadow: 0 0 0 1px #ffffff20;
}

.image {
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

@media screen and (max-width: 1024px) {
    .skills-experience {
        height: auto;
        padding: 0;
        padding-bottom: 10px;
        margin: 1rem;
    }
    .skills {
        grid-template-columns: minmax(fit-content, 1fr);
    }
    .skill {
        padding: 0;
        min-height: 150px;
    }
    .skills-wrapper h1 {
        margin: 0 2rem;
        padding-bottom: 1rem;
    }
    .skills-wrapper > svg {
       display: none;
    } 
}
@media screen and (max-width: 450px) {

    .skills-wrapper h1 {
        margin: 0 1rem;
    }

    .svg-wrapper {
        width: 100%;
        height: auto;
    }
    .skills {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin: 0;
    }
    .skills-wrapper h1 {
        margin: 0 2rem;
        z-index: 3;
        position: relative;
    }
    .skills-wrapper .svg-wrapper > svg {
        position: absolute;
        top: 13vh;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }
    .skill {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between; /* This ensures even space distribution */
        padding: 20px;
        height: 100px; /* Set a fixed height */
        width: 100%;
    }
}