.hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    margin: 0 4rem;
    height: 100vh;
}

body.dark-mode .hero h2 {
    color: rgb(135, 206, 250);
}

.hero h2 {
    color: rgb(22, 87, 125);
    padding-top: 10px;
}

.dark-mode .hero-wrapper {
    background-color: black;
}

.dark-mode .hero h1,
.dark-mode span {
    color: white;

}

.hero .left {
    flex: 1;
}

.left h1 {
    color: #000;
}

body.dark-mode .highlight {
    color: rgb(135, 206, 250);
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
}

.highlight {
    color: rgb(22, 87, 125);
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-style: italic;
}

.hero .right {
    flex: 1;
    text-align: center;
}

.profil-img-container img {
    width: 50%;
    border-radius: 50%;
}

/* Links btn  */

.links ul {
    margin-top: 2em;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 10px;
}

.links ul li {
    list-style: none;
    min-width: 90px;
}

.links a {
    display: flex;
    flex-direction: column;
    background-color: #dce9ed;
    text-decoration: none;
    text-transform: capitalize;
    border-radius: 5px;
    color: #000;
    flex: 1;
    font-size: 1.5rem;
}

.links a:hover {
    background-color: #000;
    color: #dce9ed;
}

@media screen and (max-width: 1024px) {
    .hero {
        height: 80dvh;
    }
}

@media screen and (max-width: 450px) {

    .hero {
        flex-direction: column;
        margin: 1rem 2rem 0 2rem;
        height: 60vh;
    }

    .hero h1 {
        font-size: 5vw;
        line-height: 1.5;
    }

    .hero h2 {
        font-size: 4vw;
    }

    .links {
        margin-top: 1em;
    }

    .links a {
        font-size: 1em;
    }

    .links svg {
        font-size: 1.5em;
    }
}