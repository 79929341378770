footer {
    position: relative;
    color: #dce9ed;
    background-color: #16577d;
    padding: 20px;
}

body.dark-mode footer {
    background-color: #1d1d1d;
}

.footerContainer {
    margin: 0 4rem;
}

.svgShape svg {
    position: absolute;
    bottom: 0;
    z-index: -1;
}

footer .logo {
    display: flex;
    align-items: center;
    gap: 20px;
}

.logo p {
    text-transform: uppercase;
}

.bottomsection span {
    font-size: 1em;
}

.bottomsection {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bottomsection .links {
    display: flex;
    align-items: center;
    gap: 20px;
}

.bottomsection .links a {
    background: none;
    color: #dce9ed;
    margin-bottom: 10px;
}

.bottomsection .links a:hover {
    transform: translateY(-5px);
    transition: 330ms ease-in-out;
}

@media screen and (max-width: 450px) {
    footer {
        background-color: #16577d;
        padding-top: 10vh;
    }
    .dark-mode footer {
        background-color: #1d1d1d;
    }
    .footerContainer {
        margin: 0 1rem;
    }
    .footerContainer h3 {
        font-size: 1.15em;
        max-width: 70%;
    }
    .bottomsection {
        justify-content: space-evenly;
    }   
}