body,
ul,
header {
    margin: 0;
    padding: 0;
}

header {
    display: block;
    background-color: #16577d;
    padding: 5px 1.5rem;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 9999999;
}

.dark-mode header {
    border-bottom: 2px solid white;
    background-color: black;
}

header ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    width: 100%;
}

.logo img {
    width: 100px;
    height: auto;
    border-radius: 50%;
}

header div.logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #FFFFFF;
    gap: 10px;
}

.logo li:nth-child(2) span {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 600;
}

header li span,
header li svg {
    color: #FFFFFF;
}

header li svg {
    height: 24px;
    width: 24px;
}

.menu li:hover {
    transform: translateY(-5px);
    transition: 330ms ease-in-out;
    color: black;
    cursor: pointer;
}

.menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    flex-grow: 1;
}

header button {
    background-color: #16577d;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

.dark-mode header button {
    background-color: black;
    color: white;
}

.hamburger-menu>svg {
    display: none;
}

nav.mobile-menu {
    display: none;
}

@media screen and (min-width: 1280px) {
    header li svg, header button svg {
        height: 34px;
        width: 34px;
    } 
}

@media screen and (max-width: 1280px) {
    header ul {
        gap: 10px;
    }
    header li svg, header button svg {
        height: 24px;
        width: 24px;
    } 
}

@media screen and (max-width: 1024px) {
    header {
        padding: 10px 1.5rem;
        position: sticky;
        top: 0px;
        z-index: 9999999;
    }

    .dark-mode header {
        border: none;
    }

    header li span {
        font-size: 1.25em;
    }

    nav ul li {
        font-size: 1.5em;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    header .logo img {
        width: 70px;
    }

    .menu>svg {
        display: none;
    }

    .menu>span {
        display: none;
    }

    .hamburger-menu>svg {
        display: block;
    }

    li.hamburger-menu {
        height: 27px;
    }

    nav.mobile-menu {
        display: none;
        transform: translateX(100%);
    }

    nav.mobile-menu {
        transform: translateX(100%);
        transition: transform 0.7s ease-in-out;
        position: fixed;
        top: 0;
        right: 0;
        width: 70%;
        height: 100vh;
        background-color: #16577d;
        padding: 1rem;
        z-index: 100;
        display: block;
    }

    .dark-mode .mobile-menu {
        background-color: black;
    }

    nav.open {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        width: 70%;
        height: 100vh;
        background-color: #16577d;
        padding: 1rem;
        z-index: 100;
        color: white;
        transform: translateX(0);
    }

    .dark-mode nav.open {
        background-color: black;
    }

    nav.open ul {
        display: flex;
        flex-direction: column;
        gap: 0;
        align-items: center;
        justify-content: space-around;
        justify-items: center;
        height: 100dvh;
    }

    .mobile-menu.open ul>svg {
        position: absolute;
        top: 10px;
        right: 10px;
        color: white;
        font-size: 3em;
    }
}

@media screen and (max-width: 450px) {
    header {
        padding: 10px 1.5rem;
        position: sticky;
        top: 0px;
        z-index: 9999999;
    }

    header button>svg {
        font-size: 1.5em;
    }

    .dark-mode header {
        border: none;
    }

    header li span {
        font-size: 1.25em;
    }

    nav ul li {
        font-size: 1.5em;
    }

    .mobile-menu.open svg {
        font-size: 1.25em;
    }

    header .logo img {
        width: 50px;
        height: 50px;
    }

    .menu>svg {
        display: none;
    }

    .menu>span {
        display: none;
    }

    .hamburger-menu>svg {
        display: block;
        margin-bottom: 5px;
        font-size: 1.5em;
    }

    nav.mobile-menu {
        display: none;
    }

    nav.mobile-menu ul {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: space-around;
        justify-items: center;
        height: 80vh;
    }

    nav.mobile-menu {
        transform: translateX(100%);
        transition: transform 0.7s ease-in-out;
        position: fixed;
        top: 0;
        right: 0;
        width: 70%;
        height: 100vh;
        background-color: #16577d;
        padding: 1rem;
        z-index: 100;
        display: block;
        color: white;
    }

    .dark-mode nav.mobile-menu {
        background-color: black;
    }

    nav.open {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        width: 70%;
        height: 100vh;
        background-color: #16577d;
        padding: 1rem;
        z-index: 100;
        color: white;
        transform: translateX(0);
    }

    nav.open ul {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: space-around;
        justify-items: center;
        height: 80vh;
    }

    .mobile-menu.open > svg {
        position: absolute;
        top: 10px;
        right: 10px;
        color: white;
        font-size: 3em;
    }
}