/* Font size */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* Cela aide à contrôler le layout et à prévenir des surprises avec la taille des boîtes */
}

body.dark-mode {
  background-color: black;
  color: white;
}

html {
  font-size: 16px;
}

footer {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 3vw;
  color: rgb(22, 87, 125);
}

body.dark-mode h1 {
  color: rgb(135, 206, 250);
}

h2 {
  font-size: 2vw;
  margin: 0;
  font-style: italic;
}

p {
  font-size: 1.5rem;
  /* 24px */
}

a {
  text-decoration: none;
}

span {
  font-size: 1.5em;
}

/* Pour Chrome, Safari, et Edge */
::-webkit-scrollbar {
  width: 12px;
  /* Largeur de la barre de défilement */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Couleur du "chemin" ou "track" de la barre de défilement */
}

::-webkit-scrollbar-thumb {
  background: black;
  /* Couleur de la partie déplaçable de la barre de défilement */
  border: 3px solid #ffffff;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Couleur de la partie déplaçable lors du survol */
}

/* Pour Firefox */
* {
  scrollbar-width: thin;
  /* "auto" ou "thin" ou "none" */
  scrollbar-color: #888 #f1f1f1;
  /* Couleur du thumb et du track */
}

/* Taille police pour smartphones */

@media screen and (max-width: 450px) {
  h1 {
    font-size: 8vw;
  }

  h2 {
    font-size: 6vw;
  }

  span {
    font-size: 1.75em;
  }
  p {
    font-size: 1.25em;
  }
}