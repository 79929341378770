.projects-wrapper {
    height: auto;
    padding-bottom: 5vh;
}

.projects {
    margin: 0;
}

.projects h1 {
    margin: 0 2rem;
}

.dark-mode .projects-wrapper {
    background-color: black;
}

.dark-mode .projects .card {
    border-radius: 10px;
}

/* Styles existants */
.cards {
    display: flex;
    justify-content: space-around;
    gap: 10px;
    min-height: 100vh;
    margin: 20vh 1rem;
}

.dark-mode .projects .cards {
    margin-bottom: 0;
}

.projects .card {
    border: 2px solid rgb(22, 87, 125);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.projects .card p {
    padding-left: 5px;
    line-height: 1.3;
}

/* Styles ajoutés pour l'alignement individuel */
.card:first-child {
    align-self: flex-end;
    padding: 4em 2em;
    flex: 1;
}

.card:nth-child(2) {
    align-self: center;
    padding: 4em 2em;
    flex: 1;
}

.card:last-child {
    align-self: flex-start;
    padding: 4em 2em;
    flex: 1;
}

.card:first-child:hover {
    background: rgb(169, 188, 199);
    background: linear-gradient(90deg, rgba(169, 188, 199, 1) 28%, rgba(139, 180, 196, 1) 33%, rgba(22, 87, 125, 1) 58%);
    border-radius: 10px;
    color: #fff;
}

.card:nth-child(2):hover {
    background: rgb(22, 87, 125);
    background: linear-gradient(90deg, rgba(22, 87, 125, 1) 24%, rgba(139, 180, 196, 1) 72%, rgba(169, 188, 199, 1) 87%);
    border-radius: 10px;
    color: #fff;
}

.card:last-child:hover {
    background: rgb(139, 180, 196);
    background: linear-gradient(90deg, rgba(139, 180, 196, 1) 26%, rgba(22, 87, 125, 1) 40%, rgba(169, 188, 199, 1) 87%);
    border-radius: 10px;
    color: #fff;
}

.projects-wrapper .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.top a.svg-link {
    padding: 0.75rem 1rem;
    background-color: rgb(22, 22, 22);
    margin-bottom: 0.5rem;
    border-radius: 50%;
}

.top a>svg {
    color: white;
    font-size: 1.5rem;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.img img {
    width: 100%;
    height: 80%;
    border-radius: 5px;
}

.projects-swipper {
    display: none;
}

.projects .top a {
    text-decoration: none;
    color: black;
}

body.dark-mode .top a {
    color: white;
    text-decoration: none;
}

@media screen and (max-width: 1024px) {
    .projects-wrapper {
        height: auto;
    }
    .projects {
        margin: 0;
    }
    .projects h1 {
        padding: 1rem 1rem;
    }
    .cards {
        flex-direction: column;
        margin: 0 10px;
    }
    .card:first-child {
        padding: 2rem 1rem;
    }
    .card:nth-child(2) {
        padding: 2rem 1rem;
    }
    .card:last-child {
        padding: 2rem 1rem;
    }
    .img img {
        height: 60%;
        width: 100%;
    }

    .projects .cards .top h2 {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 450px) {

    .projects h1 {
        margin: 0;
    }

    .img img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

    .cards {
        flex-direction: column;
        height: auto;
        display: none;
    }

    .card-swipper a {
        color: black;
    }

    .dark-mode .card-swipper a {
        color: white;
    }

    .card-swipper .top {
        padding: 10px 0;
    }

    .card-swipper .top a {
        padding: 0.5rem 0.75rem;
    }

    .dark-mode .card-swipper .top a {
        background-color: black;
        color: white;
        text-decoration: none;
    }

    .card-swipper .top a>svg {
        color: white;
    }

    .dark-mode .card-swipper .top a>svg {
        color: black;
    }

    .projects-swipper {
        display: block;
    }

    swiper-container {
        width: 100%;
        text-align: center;
        padding: 10px;
    }
}