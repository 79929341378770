.downloadCv-wrapper {
    background-color: #16577d;
    padding: 2em 0;
    position: relative;
}

.dark-mode .downloadCv-wrapper {
    background-color: black;
}

.downloadCv-container {
    margin: 4rem;
    padding: 4em;
    border-radius: 20px;
    background-color: #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
}

.dark-mode .downloadCv-container {
    background-color: black;
    border: 2px solid #16577d;
}

.downloadCv-container .left {
    display: flex;
    align-items: center;
    gap: 1.5em;
}

.question {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    align-items: center;
    justify-content: center;
}

.question h2 {
    color: rgb(37, 96, 131);
    font-style: normal;
}

.question p {
    color: rgb(74, 112, 134);
    font-weight: 600;
}

.downloadCv-container a {
    text-decoration: none;
    padding: 2em 3em;
    border-radius: 20px;
    background-color: rgb(46, 101, 133);
    display: flex;
    align-items: center;
    gap: 20px;
    color: white;
}

.downloadCv-container a:hover {
    background-color: black;
}

body.dark-mode .downloadCv-container a:hover {
    background-color: rgb(51, 97, 124);
}

.downloadCv-container .left svg {
    font-size: 4em;
    color: rgb(51, 97, 124);
}

.downloadCv-container .right {
    display: flex;
    align-items: center;
}

.dark-mode .downloadCv-wrapper > svg {
    background-color: black;
}

@media screen and (max-width: 1064px){
    .downloadCv-container {
        margin: 0 2rem;
    }
}

@media screen and (max-width: 450px){
    .downloadCv-wrapper {
        margin-top: 20vh;
    }
    .dark-mode .downloadCv-wrapper {
        margin-top: 0;
    }
    .downloadCv-container {
        flex-direction: column;
        margin: 2rem;
        padding: 2em;
    }
    .downloadCv-wrapper > svg {
        top: -15%;
    }
}